import React from "react"
import { useCourseStore } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import PaperStack from "../../../../../components/paper-stack"
import Constrain from "../../../../../components/constrain"
import Paragraph from "../../../../../components/paragraph"
import Poem from "../../../../../components/poem"
import NotationSingle from "../../../../../components/notation-single"
import CourseFooterNext from "../../../../../components/course-footer-next"
import FeedbackHeaderBool from "../../../../../components/feedback-header-bool"
import { getCourseNavigation } from "../../../../../store/courses"

const Page = () => {
  const { booleanAnswerIsValid } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "versteckte-ordnung" })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext
          title="Bereit?"
          label="Weiter"
          to="/kurse/versteckte-ordnung/01-gedicht-erkennen/feiertage"
        />
      }
    >
      <Seo title="Das ist ein Gedicht von Theodor Fontane" />
      <Stack>
        <FeedbackHeaderBool
          confirmed={booleanAnswerIsValid({
            courseId: "versteckte-ordnung",
            chapterId: "01-gedicht-erkennen",
            taskId: "maisonntag",
          })}
          title="Das ist ein Gedicht von Theodor Fontane"
        />
        <Paragraph>
          Das Gedicht ist dem Monat Mai gewidmet, der in der 1. Strophe
          wiederholt mit "Du" angesprochen wird. Schon durch diese
          Wiederholungen entsteht eine Melodie, wie in einem Gesang. Das wird
          durch den Kreuzreim am Ende der Zeilen noch verstärkt.
        </Paragraph>
        <Constrain align="left">
          <PaperStack>
            <Poem>
              <p>
                <NotationSingle color="winter">Du</NotationSingle> klare Luft,
                du liebe <NotationSingle color="spring">Sonne</NotationSingle>,
              </p>
              <p>
                <NotationSingle color="winter">Du</NotationSingle> grüner Wald,
                du Blüten
                <NotationSingle color="autumn">tal</NotationSingle>
              </p>
              <p>
                <NotationSingle color="winter">Du</NotationSingle> ganze große
                Maien
                <NotationSingle color="spring">wonne</NotationSingle>,
              </p>
              <p>
                sei mir gegrüßt viel tausend
                <NotationSingle color="autumn">mal</NotationSingle>.
              </p>
              <br />
              <p>Wie regungslos ob deiner Schöne</p>
              <p>Hemmt seinen Lauf der Morgenwind,</p>
              <p>Und Vogelsang und Glockentöne</p>
              <p>Nur in der Luft lebendig sind.</p>
            </Poem>
          </PaperStack>
        </Constrain>
        <Paragraph>
          Das Gedicht hat eine feste Form: Es besteht aus 2 Strophen mit jeweils
          4 Versen. Jede Strophe des Gedichts besteht aus einem Satz. Dafür hat
          Theodor Fontane seine Sätze so gebaut, dass sie sich in die Form der
          Strophen einfügen.
        </Paragraph>
        <Constrain align="left">
          <PaperStack>
            <Poem>
              <p>Du klare Luft, du liebe Sonne,</p>
              <p>Du grüner Wald, du Blütental</p>
              <p>Du ganze große Maienwonne,</p>
              <p>
                sei mir gegrüßt viel tausendmal
                <NotationSingle type="circle">.</NotationSingle>
              </p>
              <br />
              <p>Wie regungslos ob deiner Schöne</p>
              <p>Hemmt seinen Lauf der Morgenwind,</p>
              <p>Und Vogelsang und Glockentöne</p>
              <p>
                Nur in der Luft lebendig sind
                <NotationSingle type="circle">.</NotationSingle>
              </p>
            </Poem>
          </PaperStack>
        </Constrain>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
