import React from "react"
import { useThemeUI } from "theme-ui"
import { RoughNotation } from "react-rough-notation"
import PropTypes from "prop-types"

const NotationSingle = ({ children, show, type, color, ...restProps }) => {
  const context = useThemeUI()
  const { theme } = context

  return (
    <RoughNotation
      show={show}
      type={type}
      color={theme.colors[color]}
      {...restProps}
    >
      {children}
    </RoughNotation>
  )
}

NotationSingle.propTypes = {
  color: PropTypes.string,
  show: PropTypes.bool,
}

NotationSingle.defaultProps = {
  color: "primary",
  type: "underline",
  show: true,
}

export default NotationSingle
